import {IQuestionDetail, IQuestionDetailId, ITest, ITestDetail, ITestLeft} from "../models/tests";
import api from "./api";

export const TestsService = {
    async getAll() {
        const { data } = await api.get<ITest[]>(`/api/Test`)
        return data
    },

    async getByPage(page: number, search?: string) {
        const { data } = await api.get<ITestLeft>(`/api/Test/page/${page}/${search}`)
        return data
    },

    async getById(id: number | undefined) {
        const response = await api.get<ITest>(`/api/Test/${id}`)
        return response.data
    },

    async create(data: ITestDetail) {
        const response = await api.post<ITest>(`/api/Test/add`, data)
        return response.data
    },

    async update(data: ITestDetail, id: number) {
        const response = await api.post<ITest>(`/api/Test/update/${id}`, data)
        return response.data
    },

    async createQuestions(id: number, data: IQuestionDetail[]) {
        const response = await api.post<ITest>(`/api/Test/questions/${id}`, data)
        return response.data
    },

    async delete(data: IQuestionDetailId) {
        return await api.post<boolean>(`/api/Test/delete/`, data)
    }
}