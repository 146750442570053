import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {ReactComponent as ArrowAvatar} from "../../../assets/images/global/arrow-avatar.svg";
import avatarDefault from "../../../assets/images/global/avatar.jpg"
import {ReactComponent as EditIcon} from "../../../assets/images/global/edit-icon.svg";
import {ReactComponent as LogoutIcon} from "../../../assets/images/global/logout-icon.svg";
import {ReactComponent as LogoMobile} from "../../../assets/images/global/logo-mobile.svg";
import {ReactComponent as NavigationMenuMobileIcon} from "../../../assets/images/global/navigation-menu-mobile-icon.svg";
import {
    ReactComponent as CloseNavigationMenuMobileIcon
} from "../../../assets/images/global/close-navigation-menu-mobile-icon.svg";
import {Link, useLocation} from "react-router-dom";
import Cookies from "universal-cookie"
import {AuthContext} from "../../../context/AuthContext";
import {useQuery} from "react-query";
import {UsersService} from "../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../error/ErrorMessage";
import {Constants} from "../../../constans/constants";
import Select from "react-select";
import ScrollContainer from "../scroll-container/ScrollContainer";
import {logout} from "../../../helpers/HandleLogout";

interface Layout {
    children: ReactNode
}

const Layout = (
    {
        children
    }: Layout
) => {

    let location = useLocation()

    const [avatarUser, setAvatarUser] = useState<string>("")
    const [viewEvent, setViewEvent] = useState<boolean>(false)
    const [viewUserMenu, setViewUserMenu] = useState<boolean>(false)
    const eventRef = useRef<HTMLDivElement>(null)
    const userRef = useRef<HTMLDivElement>(null)
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)
    const [showSelectDomain, setShowSelectDomain] = useState<boolean>(false)
    const [showScrollInLayout, setShowScrollInLayout] = useState<boolean>(true)
    const cookies = new Cookies()

    const handleClickEvent = () => {
        setViewEvent(!viewEvent)
    }
    useEffect(() => {
        if (!viewEvent) return
        handleClickRef(eventRef, handleClickEvent)
    }, [viewEvent])

    const handleClickUser = () => {
        setViewUserMenu(!viewUserMenu)
    }
    useEffect(() => {
        if (!viewUserMenu) return
        handleClickRef(userRef, handleClickUser)
    }, [viewUserMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (element.classList.contains("unclose")) return
            if (element.classList.contains("close")) {
                handleSet()
            }
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    const handleLogout = () => {
        logout()
        setAuthenticated(false)
        setViewEvent(false)
        setViewUserMenu(false)
    }

    useEffect(() => {
        Constants.LIST_APOK_DOMAIN.map((element) => {
            if(element.value.indexOf(window.location.host) !== -1) {
                setShowSelectDomain(true)
            }
        })
    }, [])

    useEffect(() => {
        let findAttachLesson: boolean = false
        Constants.LIST_WITHOUT_SCROLL_IN_LAYOUT.map((element) => {
            if(window.location.pathname.indexOf(element) !== -1) {
                findAttachLesson = true
            }
        })
        findAttachLesson ? setShowScrollInLayout(false) : setShowScrollInLayout(true)
    }, [window.location.pathname]);

    const onChange = (data: any) => {
        window.open("https://" + data.value, '_blank')
    }

    const currentUser = useQuery(
        ["current_user", currentUserId],
        () => UsersService.getById(currentUserId),
        {
            enabled: !!cookies.get("user"),
            onSuccess: (response) => {
                if(response.avatar)
                    setAvatarUser(response.avatar)
            }
        }
    )

    if(currentUser.isLoading) return <div className="flex items-center justify-center w-full">Загрузка...</div>

    if (currentUser.isError) {
        // const e = currentUser.error as AxiosError
        // return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
        logout()
        setAuthenticated(false)
    }

    // if(!currentUser.data) return <ErrorMessage numberError={400}/>

    return (
        <>
            {!authenticated ? (
                <>
                    {children}
                </>
            ) : (
                <div className="w-full h-full flex flex-col">
                    {/*max-lg:max-h-[calc(100%-62px)]*/}
                    {authenticated && (
                        <div
                            className="px-[36px] py-[18px] bg-[#F2F2F2] border-b-[2px] border-[#BDBDBD] flex justify-end items-center max-lg:px-[24px] max-lg:py-[12px] max-lg:justify-center max-lg:bg-[#FFFFFF]">
                            <div
                                className="flex gap-[24px] items-center max-lg:justify-between max-lg:gap-0 max-lg:w-full relative">
                                {/*{Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 && (*/}
                                {/*    <div ref={eventRef} className="relative">*/}
                                {/*        <div*/}
                                {/*            className="max-lg:rounded-[5px] max-lg:border-[1px] max-lg:border-solid max-lg:border-[#17AB57] max-lg:py-[8px] max-lg:px-[12px]"*/}
                                {/*            onClick={handleClickEvent}>*/}
                                {/*            <EventsLogo*/}
                                {/*                className={`hover:fill-[#17AB57] cursor-pointer max-lg:w-[18px] max-lg:h-[18px]` + (viewEvent ? " fill-[#17AB57]" : "")}/>*/}
                                {/*        </div>*/}
                                {/*        {viewEvent && (*/}
                                {/*            <div*/}
                                {/*                className="close max-lg:w-full max-lg:h-full max-lg:fixed max-lg:top-0 max-lg:left-0 max-lg:bg-[#00000040] max-lg:z-[100]">*/}
                                {/*                <div*/}
                                {/*                    className="rounded-[6px] z-[100] w-[320px] max-h-[300px] absolute top-[25px] right-0 bg-white overflow-hidden overflow-y-auto max-lg:left-[24px] max-lg:top-[65px] shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] scroll-smooth scrollbar scrollbar-w-[5px] scrollbar-track-[#E0E0E0] scrollbar-thumb-[#BDBDBD] scrollbar-thumb-rounded-[6px] scrollbar-track-rounded-[6px]">*/}
                                {/*                    {eventsStudent.isLoading && (<div className="px-[12px] py-[14px] flex items-center justify-center w-full">Загрузка...</div>)}*/}
                                {/*                    {eventsStudent.isError && (<ErrorMessage numberError={400}/>)}*/}
                                {/*                    {eventsStudent.data && eventsStudent.data.listEvents.map((event, index) => (*/}
                                {/*                        <EventItem item={event} key={index}/>*/}
                                {/*                    ))}*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                <LogoMobile
                                    className="hidden max-lg:block absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[10]"/>
                                <div ref={userRef} className="flex gap-[8px] items-center relative justify-end w-full">
                                    <div className="w-[48px] h-[48px] rounded-[37px] overflow-hidden max-lg:hidden">
                                        {avatarUser ? (
                                            <img src={avatarUser} className="w-full h-full object-cover" alt=""/>
                                        ) : (
                                            <div
                                                className={`w-full h-full flex justify-center items-center text-white font-[500] text-[24px] ${Constants.LIST_COLORS[Number(currentUser.data?.color)]}`}>
                                                {currentUser.data?.lastName.trim()[0]}{currentUser.data?.firstName.trim()[0]}
                                            </div>
                                        )}
                                    </div>
                                    <div className="close flex gap-[6px] items-center group " onClick={handleClickUser}>
                                        <div
                                            className="max-lg:block hidden rounded-[5px] py-[8px] px-[12px] bg-[#17AB57] cursor-pointer">
                                            <NavigationMenuMobileIcon className=""/>
                                        </div>
                                        <div
                                            className={`rounded-[5px] py-[8px] px-[12px] bg-[#17AB57] cursor-pointer hidden` + (viewUserMenu ? " max-lg:block z-[110] absolute" : " hidden")}>
                                            <CloseNavigationMenuMobileIcon className="close"/>
                                        </div>
                                        <div className="max-lg:hidden">
                                            <div
                                                className={`text-[#292B2C] group-hover:text-[#17AB57] cursor-pointer` + (viewUserMenu ? " !text-[#17AB57]" : "")}>
                                                {currentUser.data?.lastName} {currentUser.data?.firstName[0]}.{currentUser.data?.fatherName ? currentUser.data?.fatherName[0] + "." : ""}
                                            </div>
                                            <div className="text-[#BDBDBD]">{currentUser.data?.role.name}</div>
                                        </div>
                                        <ArrowAvatar
                                            className={`max-lg:hidden cursor-pointer group-hover:fill-[#17AB57]` + (viewUserMenu ? " fill-[#17AB57]" : "")}/>
                                    </div>
                                    {viewUserMenu && (
                                        <>
                                            <div
                                                className="z-[100] max-lg:hidden rounded-[6px] absolute top-[50px] right-0 bg-white overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)]">
                                                <div
                                                    className="px-[12px] py-[10px] flex gap-[6px] hover:bg-gradient-to-t hover:from-[#17AB571a] hover:from-0% hover:to-[#17AB571a] hover:to-100% items-center cursor-pointer">
                                                    <EditIcon/>
                                                    <Link onClick={() => {
                                                        setViewEvent(false);
                                                        setViewUserMenu(false)
                                                    }} className="text-[#292B2C]"
                                                          to={`/users/${currentUser.data?.id}`}>Настройки</Link>
                                                </div>
                                                <div onClick={handleLogout}
                                                     className="px-[12px] py-[14px] flex gap-[6px] hover:bg-gradient-to-t hover:from-[#17AB571a] hover:from-0% hover:to-[#17AB571a] hover:to-100% items-center cursor-pointer">
                                                    <LogoutIcon/>
                                                    <div className="text-[#292B2C]">Выйти</div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className={
                                        `close max-lg:block w-0 max-lg:h-full fixed overflow-hidden
                                max-lg:top-0 max-lg:left-0 max-lg:bg-[#00000040] max-lg:z-[100] transition-all` + (viewUserMenu ? " max-lg:w-full" : "")
                                    }>
                                        <div
                                            className={`unclose bg-white p-[32px] h-full overflow-hidden flex flex-col gap-[24px] w-0 transition-all` + (viewUserMenu ? " max-lg:w-[300px]" : "")}>
                                            <div className="flex gap-[8px]">
                                                <div className="w-[48px] h-[48px] rounded-[37px] overflow-hidden">
                                                    <img src={avatarUser} className="w-full h-full object-cover"
                                                         alt=""/>
                                                </div>
                                                <div className="">
                                                    <div
                                                        className="text-[#292B2C]">{currentUser.data?.lastName} {currentUser.data?.firstName[0]}.{currentUser.data?.fatherName ? currentUser.data?.fatherName[0] + "." : ""}</div>
                                                    <div className="text-[#BDBDBD]">{currentUser.data?.role.name}</div>
                                                </div>
                                            </div>
                                            <Link onClick={() => {
                                                setViewEvent(false);
                                                setViewUserMenu(false)
                                            }} className="text-[#292B2C]"
                                                  to={`/users/${currentUser.data?.id}`}>Настройки</Link>
                                            {showSelectDomain && Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 && (
                                                <div className="text-[#BDBDBD]">
                                                    <label className="inline-block">Сменить домен</label>
                                                    <Select
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 5,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#17AB57",
                                                                primary25: "#17AB571a"
                                                            }
                                                        })}
                                                        value={
                                                            Constants.LIST_APOK_DOMAIN.filter(option =>
                                                                option.value === window.location.host)
                                                        }
                                                        onChange={onChange}
                                                        menuPlacement="bottom"
                                                        options={Constants.LIST_APOK_DOMAIN}/>
                                                </div>
                                            )}
                                            <div onClick={handleLogout}
                                                 className="text-[#292B2C] cursor-pointer">Выйти
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {location.pathname.indexOf("messages") === -1 ? (
                        <div className="bg-[#F2F2F2] h-full p-[36px] max-lg:p-[24px]">
                            {showScrollInLayout ? (
                                <ScrollContainer chat={false}>
                                    {children}
                                </ScrollContainer>
                            ):(
                                <>
                                    {children}
                                </>
                            )}
                        </div>
                    ) : (
                        <>
                            {children}
                        </>
                    )}
                </div>
            )}
        </>

    )
}

export default Layout