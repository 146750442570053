export default function GetCurrentDomain(){

    let domain = ""
    let location = window.location
    let hostname = location.hostname
    let subdomain = hostname.split(".")
    if(subdomain[subdomain.length - 1] === "ru" && subdomain.length === 3) {
        domain = subdomain[0]
    } else if(subdomain[subdomain.length - 1] !== "ru" && subdomain.length === 2) {
        domain = subdomain[0]
    }

    return domain
}