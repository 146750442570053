import axios from "axios";
import {Constants} from "../constans/constants";
import Cookies from "universal-cookie";
import {IUserAuth} from "../models/login";
import GetCurrentDomain from "../helpers/GetCurrentDomain";

const API_URL = Constants.LOCATION
const cookies = new Cookies()
const api = axios.create({
    baseURL: API_URL,
})
const domain = GetCurrentDomain()

const refreshToken = async () => {
    try {
        const refreshToken = cookies.get("refreshToken");
        const {data} = await axios.post<IUserAuth>(`${API_URL}/api/User/refreshToken`, {refreshToken}, {
            headers: {'Domain': domain}
        })

        cookies.set("token", data.accessToken, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
        cookies.set("refreshToken", data.refreshToken, {expires: new Date(Date.now() + 180 * 24 * 60 * 60 * 1000), path: "/"})

        return data.accessToken
    } catch (error){
        throw error;
    }
}

api.interceptors.request.use(
    (config) => {
        const accessToken = cookies.get("token")
        if(accessToken){
            config.headers.Authorization = `Bearer ${accessToken}`
        }


        config.headers.Domain = domain

        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => response,
    async (error) => {

        const originalRequest = error.config
        console.log(originalRequest)
        if(error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                const newAccessToken = await refreshToken()
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`

                return api(originalRequest)
            } catch (refreshError) {

                cookies.remove("token", {path: "/"})
                cookies.remove("role", {path: "/"})
                cookies.remove("user", {path: "/"})
                cookies.remove("refreshToken", {path: "/"})

                return Promise.reject(error)
            }
        }

        return Promise.reject(error)
    }
)

export default api