import Cookies from "universal-cookie";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useRef, useState} from "react";
import {useQuery} from "react-query";
import {UsersService} from "../../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as CheckboxUnchecked} from "../../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as RadioUnchecked} from "../../../../../assets/images/tests/radio-unchecked.svg";
import {ReactComponent as WrongAnswer} from "../../../../../assets/images/tests/wrong-answer.svg";
import {ReactComponent as CorrectAnswer} from "../../../../../assets/images/tests/correct-answer.svg";
import {Constants} from "../../../../../constans/constants";
import avatarDefault from "../../../../../assets/images/global/avatar.jpg";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import {AuthContext} from "../../../../../context/AuthContext";

const StudentLessonTestViewResult = () => {

    const [avatarUser, setAvatarUser] = useState<string>(avatarDefault)
    const cookies = new Cookies()
    const {userId, id, testId, lessonId} = useParams()
    const ref = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const test = useQuery(
        ["result_test", testId, id, currentUserId, lessonId, userId],
        () => UsersService.getResultTest(userId ? Number(userId) : Number(currentUserId), Number(testId), Number(lessonId)),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                if(response.isBlocked) navigate("/student-courses")
            }
        }
    )

    const user = useQuery(
        ["result_test", userId],
        () => UsersService.getById(userId),
        {
            enabled: !!userId,
            onSuccess: (response) => {
                if(response.avatar)
                    setAvatarUser(response.avatar)
            }
        }
    )

    const handleBack = () => {
        if(userId) {
            navigate(`/users/${userId}/course/${id}`)
        } else {
            navigate(`/student-courses/${id}`)
        }
    }

    if (test.isLoading) return <div className="text-center">Загрузка...</div>

    if (test.isError) {
        const e = test.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!test.data) return <div className="text-center">Ошибка данных</div>

    return (
        <>
            <div className="flex h-full gap-[24px] flex-col max-lg:gap-[12px]">
                <div className="hidden max-lg:flex ">
                    <div
                        onClick={handleBack}
                        className="cursor-pointer px-[16px] py-[12px] rounded-[5px] border-solid border-[#17AB57] border-[1px] flex items-center gap-[6px] text-[20px]">
                        <ArrowBackIcon className="flex-shrink-0"/>
                        Назад
                    </div>
                </div>

                <div
                    className="text-[#292B2C] w-full bg-[#FFF] p-[32px] rounded-[6px] flex items-center justify-between max-lg:p-[14px]">
                    <div className="text-[36px] font-bold leading-[120%] max-lg:text-[24px]">Отчет. {test.data.name}</div>
                    <div
                        onClick={handleBack}
                        className="max-lg:hidden cursor-pointer px-[16px] py-[12px] rounded-[5px] border-solid border-[#17AB57] border-[1px] flex items-center gap-[6px] text-[20px]">
                        <ArrowBackIcon className="flex-shrink-0"/>
                        Назад
                    </div>
                </div>
                <div className="flex gap-[12px] h-full min-h-0 max-2xl:flex-col">
                    {/*<ScrollContainer >*/}
                        <div ref={ref} className="h-full flex flex-col gap-[24px] w-full">
                            {test.data.questions.map((question, index) => (
                                <div
                                    key={index}
                                    className="w-full p-[32px] max-lg:p-[14px] rounded-[6px] bg-white flex-col gap-[18px] flex">
                                    <div
                                        className="leading-[120%] text-[#292B2C] text-[24px] font-medium max-lg:text-[20px]">
                                        {question.name}
                                    </div>
                                    <div className="flex flex-col gap-[12px]">
                                        {question.options.map((option, indexOption) => (
                                            <div
                                                key={indexOption}
                                                className="rounded-[4px] bg-[#F2F2F2] px-[10px] py-[12px] flex items-center justify-between font-medium">
                                                {question.countCorrectAnswer > 1 ? (
                                                    <>
                                                        {option.isCorrectAnswer ? (
                                                            <>
                                                                {option.answerUser ? (
                                                                    <label className="flex items-center gap-[6px] text-[#219653]">
                                                                        <CorrectAnswer className="flex-shrink-0"/>
                                                                        {option.name}
                                                                    </label>
                                                                ) : (
                                                                    <>
                                                                        {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 ? (
                                                                            <label className="flex items-center gap-[6px] text-[#219653]">
                                                                                <CorrectAnswer className="flex-shrink-0"/>
                                                                                {option.name}
                                                                            </label>
                                                                        ) : (
                                                                            <label className="flex items-center gap-[6px]">
                                                                                <CheckboxUnchecked className="flex-shrink-0"/>
                                                                                {option.name}
                                                                            </label>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {option.answerUser ? (
                                                                    <label className="flex items-center gap-[6px] text-[#EB5757]">
                                                                        <WrongAnswer className="flex-shrink-0"/>
                                                                        {option.name}
                                                                    </label>
                                                                ) : (
                                                                    <label className="flex items-center gap-[6px]">
                                                                        <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                                                        {option.name}
                                                                    </label>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {option.isCorrectAnswer ? (
                                                            <>
                                                                {option.answerUser ? (
                                                                    <label className="flex items-center gap-[6px] text-[#219653]">
                                                                        <CorrectAnswer className="flex-shrink-0"/>
                                                                        {option.name}
                                                                    </label>
                                                                ) : (
                                                                    <>
                                                                        {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 ? (
                                                                            <label className="flex items-center gap-[6px] text-[#219653]">
                                                                                <CorrectAnswer className="flex-shrink-0"/>
                                                                                {option.name}
                                                                            </label>
                                                                        ) : (
                                                                            <label className="flex items-center gap-[6px]">
                                                                                <RadioUnchecked className="flex-shrink-0"/>
                                                                                {option.name}
                                                                            </label>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {option.answerUser ? (
                                                                    <label className="flex items-center gap-[6px] text-[#EB5757]">
                                                                        <WrongAnswer className="flex-shrink-0"/>
                                                                        {option.name}
                                                                    </label>
                                                                ) : (
                                                                    <label className="flex items-center gap-[6px]">
                                                                        <RadioUnchecked className="flex-shrink-0"/>
                                                                        {option.name}
                                                                    </label>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    {/*</ScrollContainer>*/}
                    <div className="flex-shrink-0 w-[470px] flex flex-col gap-[18px] max-2xl:w-full ">
                        {user.data && (
                            <div className="flex flex-col gap-[18px] rounded-[6px] bg-white p-[32px] w-full">
                                <div className="text-[#292B2C] text-[24px] leading-[120%] font-bold">Пользователь</div>
                                <div className="flex items-center gap-[8px]">
                                    <div
                                        className="w-[48px] h-[48px] rounded-[49px] overflow-hidden cursor-pointer group relative flex-shrink-0">
                                        <img src={avatarUser} className="w-full h-full object-cover" alt=""/>
                                    </div>
                                    <div className="flex flex-col gap-[4px]">
                                        <div
                                            className="text-[24px] text-[#292B2C] font-bold">{user.data.lastName} {user.data.firstName[0]}. {user.data.fatherName ? user.data.fatherName[0] + "." : ""}</div>
                                        <div className="text-[16px] text-[#BDBDBD] font-medium">{user.data.role.name}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col gap-[24px] rounded-[6px] bg-white p-[32px] w-full max-lg:p-[14px]">
                            <div className="text-[#292B2C] text-[24px] leading-[120%] font-bold">Статистика</div>
                            <div className="flex justify-between flex-wrap">
                                <div className="">
                                    <div className="text-[24px] font-bold text-[#27AE60] leading-[120%] max-lg:text-[20px]">{test.data.correct}</div>
                                    <div className="text-[16px] font-[400] text-[#828282] leading-[120%]">правильных</div>
                                </div>
                                <div className="">
                                    <div className="text-[24px] font-bold text-[#EB5757] leading-[120%] max-lg:text-[20px]">{test.data.incorrect}</div>
                                    <div className="text-[16px] font-[400] text-[#828282] leading-[120%]">неправильных</div>
                                </div>
                                <div className="">
                                    <div className={`text-[24px] font-bold leading-[120%] max-lg:text-[20px] ${Number(test.data.result) >= Number(test.data.threshold) ? 'text-[#27AE60]' : 'text-[#EB5757]'}`}>
                                        {test.data.result}%
                                    </div>
                                    <div className="text-[16px] font-[400] text-[#828282] leading-[120%]">результат</div>
                                </div>
                                <div className="">
                                    <div className="text-[24px] font-bold text-[#292B2C] leading-[120%] max-lg:text-[20px]">{test.data.threshold}%</div>
                                    <div className="text-[16px] font-[400] text-[#828282] leading-[120%]">порог</div>
                                </div>
                            </div>
                        </div>
                        <Link
                            className="bg-[#17AB57] text-white rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer justify-center"
                            to={`/student-courses/${id}/lesson/${lessonId}/test-passing/${testId}`}>
                            Пересдать
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentLessonTestViewResult